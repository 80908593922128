import React, { useEffect, useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import axios from 'axios';
import { Col, Container, Row, Table, Form } from 'react-bootstrap';
import logoImg from './images/logo.png';
import lineRight from './images/lines-right.png';
import forwardImg from './images/forward-6@2x.svg';
import { CSVLink } from "react-csv";
// import lineLeft from './images/lines-right.png';
import { ethers } from 'ethers';
// import { Alchemy, Network } from "alchemy-sdk";



export default function Home() {
    const [holderData, setHolderData] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [count, setCount] = useState(0);
    const [address, setAddress] = useState('');
    const [csvData, setCsvdata] = useState([]);
    const [lastRun, setLastRun] = useState(0);
    const provider = new ethers.providers.JsonRpcProvider("https://mainnet.infura.io/v3/63f4b8ee61284419b46c780d03befc4e")
    // const config = {
    //     apiKey: "7_lKZTILdJ34aWEHlMLEaUfrly59vgzR",
    //     network: Network.ETH_MAINNET,
    // };
    // const alchemy = new Alchemy(config);
    // const ensContractAddress = "0x57f1887a8BF19b14fC0dF6Fd9B2acc9Af147eA85";

    useEffect(() => {

        async function fetchData() {
            try {
               
                if (address === '') {
                    let partnerData = await axios.get(`https://goldretriever.flashfusion.io/api/holders/`, {
                        params: {
                            page,
                            pageSize: 20
                        }
                    });


                    if (typeof partnerData.data !== 'undefined' && partnerData.data.error === 'OK' && partnerData.data.data.length > 0) {


                        Promise.all(partnerData.data.data.map(async (rowdata) => {
                            // const nfts = await alchemy.nft.getNftsForOwner(rowdata.address, {
                            //     contractAddresses: [ensContractAddress],
                            // });

                            const nfts = await provider.lookupAddress(rowdata.address);
                            // let ens_name = '';
                            // if(nfts.ownedNfts.length > 0){
                            //     nfts.ownedNfts.map((ensData)=>{
                            //         if(ensData.title !== '' && ens_name === ''){
                            //             ens_name = ensData.title;
                            //         } 
                            //         return true;
                            //     })
                            // }
                            return { ...rowdata, address : nfts && nfts !== '' ? nfts : rowdata.address}
                        })).then((finalData) => {
                            setHolderData(finalData);
                            setCount(partnerData.data.total_count);
                            setLastRun(partnerData.data.last_run);
                            setLoading(false);
                        })


                        // setTimeout(() => {
                        //     setLoading(false);
                        // }, 2000)

                    }
                    else {
                        console.log(partnerData.data.error);
                    }
                }
                else {
                    const addressReslove = await provider.resolveName(address.trim());
                    
                    // let addressReslove = await alchemy.core.resolveName(address.trim());
                    let partnerData = await axios.get(`https://goldretriever.flashfusion.io/api/holders/`, {
                        params: {
                            address: addressReslove !== null ? addressReslove : address.trim()
                        }
                    });
                    if (typeof partnerData.data !== 'undefined' && partnerData.data.error === 'OK') {
                        Promise.all(partnerData.data.data.map(async (rowdata) => {
                            // const nfts = await alchemy.nft.getNftsForOwner(rowdata.address, {
                            //     contractAddresses: [ensContractAddress],
                            // });
                            // let ens_name = '';
                            // if(nfts.ownedNfts.length > 0){
                            //     nfts.ownedNfts.map((ensData)=>{
                            //         if(ensData.title !== '' && ens_name === ''){
                            //             ens_name = ensData.title;
                            //         } 
                            //         return true;
                            //     })
                            // }
                            const nfts = await provider.lookupAddress(rowdata.address);
                            return { ...rowdata, address : nfts && nfts !== '' ? nfts : rowdata.address}
                        })).then((finalData) => {
                            setHolderData(finalData);
                            setCount(1);
                            setLoading(false);
                        })

                    }
                }
            }
            catch (err) {
                console.log(err.message);
            }
        }
        fetchData();

        // eslint-disable-next-line
    }, [page, address])


    useEffect(() => {
        async function fetchData() {
            try {

                let partnerData = await axios.get(`https://goldretriever.flashfusion.io/api/holders/`);
                if (typeof partnerData.data !== 'undefined' && partnerData.data.error === 'OK' && partnerData.data.data.length > 0) {
                    setCsvdata(partnerData.data.data);
                }
                else {
                    console.log(partnerData.data.error);
                }
            }
            catch (err) {
                console.log(err.message);
            }

        }
        fetchData();


    }, [])

    const handleAddressChange = async (e) => {
        e.preventDefault();
        setAddress(e.target.value);
        setPage(1);
        setCount(0);
        setLoading(true);
    }


    return (
        <div className='gldn-home-draft-2'>
            <Navbar expand="lg">
                <Container>
                    <Navbar.Brand href="#home">
                        <img src={logoImg} alt="logo" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ms-auto">
                            <Nav.Link href='https://app.goldretriever.io/' target="_blank" className='btn-cust me-2'>
                                Enter App<img class="forward" src={forwardImg} alt="Forward" />
                            </Nav.Link>
                            <Nav.Link href='https://goldretriever.io/prices' target="_blank" className='btn-cust'>
                                Prices<img class="forward" src={forwardImg} alt="Forward" />
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Container className='mt-5'>
                <div className='table-area'>
                    <h1 className='mt-3 mb-5 text-left text-center head-text'>GLDN Ecosystem Leaderboard</h1>
                    <Row>
                        <Col xs={6} sm={6} md={8} lg={8}>
                            <div className='search-box mb-3'>
                                <Form.Control type="text" value={address} onChange={(e) => handleAddressChange(e)} placeholder="Search" />
                            </div>
                        </Col>
                        <Col xs={6} sm={6} md={4} lg={4}>

                            <CSVLink data={csvData} className='btn btncsv ms-auto'>
                                <i class="fa fa-download"></i>&nbsp;Download
                            </CSVLink>

                        </Col>
                        <small className='text-danger'>*Last data updated on time  - {lastRun > 0 ? new Date(lastRun * 1000).toString() : '....'}</small>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <div className='table-area'>
                                <Table responsive striped hover >
                                    <thead>
                                        <tr>
                                            <th>
                                                #
                                            </th>
                                            <th>
                                                Address
                                            </th>
                                            <th>
                                                <small>Combined Rank(GLDN/BARC)</small>
                                            </th>
                                            <th>
                                                Tier
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!loading ? (holderData.length > 0 ? (
                                            holderData.map((rowdata, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{rowdata.rank_no ? rowdata.rank_no : '-'}</td>

                                                        <td>{rowdata.address ? rowdata.address : '-'}</td>
                                                        <td>{rowdata.rank ? rowdata.rank : '-'}</td>
                                                        <td>
                                                            {rowdata.type_name === 'GOLD' ? (
                                                                <span className='badges gold-badge'>GOLD</span>
                                                            ) : (
                                                                rowdata.type_name === 'SILVER' ?
                                                                    (<span className='badges silver-badge'>SILVER</span>)
                                                                    :
                                                                    (<span className='badges copper-badge'>COPPER</span>)
                                                            )}

                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        ) : (
                                            <tr className='text-center'>
                                                <td colSpan={4}>No Record Found</td>
                                            </tr>
                                        )
                                        ) :
                                            (
                                                <>
                                                    <tr>
                                                        <td><div class="shimmer shimmer-thumbnail border-rounded"></div></td>
                                                        <td><div class="shimmer shimmer-thumbnail border-rounded"></div></td>
                                                        <td><div class="shimmer shimmer-thumbnail border-rounded"></div></td>
                                                        <td><div class="shimmer shimmer-thumbnail border-rounded"></div></td>
                                                    </tr>
                                                    <tr>
                                                        <td><div class="shimmer shimmer-thumbnail border-rounded"></div></td>
                                                        <td><div class="shimmer shimmer-thumbnail border-rounded"></div></td>
                                                        <td><div class="shimmer shimmer-thumbnail border-rounded"></div></td>
                                                        <td><div class="shimmer shimmer-thumbnail border-rounded"></div></td>
                                                    </tr>
                                                    <tr>
                                                        <td><div class="shimmer shimmer-thumbnail border-rounded"></div></td>
                                                        <td><div class="shimmer shimmer-thumbnail border-rounded"></div></td>
                                                        <td><div class="shimmer shimmer-thumbnail border-rounded"></div></td>
                                                        <td><div class="shimmer shimmer-thumbnail border-rounded"></div></td>
                                                    </tr>
                                                    <tr>
                                                        <td><div class="shimmer shimmer-thumbnail border-rounded"></div></td>
                                                        <td><div class="shimmer shimmer-thumbnail border-rounded"></div></td>
                                                        <td><div class="shimmer shimmer-thumbnail border-rounded"></div></td>
                                                        <td><div class="shimmer shimmer-thumbnail border-rounded"></div></td>
                                                    </tr>
                                                    <tr>
                                                        <td><div class="shimmer shimmer-thumbnail border-rounded"></div></td>
                                                        <td><div class="shimmer shimmer-thumbnail border-rounded"></div></td>
                                                        <td><div class="shimmer shimmer-thumbnail border-rounded"></div></td>
                                                        <td><div class="shimmer shimmer-thumbnail border-rounded"></div></td>
                                                    </tr>
                                                    <tr>
                                                        <td><div class="shimmer shimmer-thumbnail border-rounded"></div></td>
                                                        <td><div class="shimmer shimmer-thumbnail border-rounded"></div></td>
                                                        <td><div class="shimmer shimmer-thumbnail border-rounded"></div></td>
                                                        <td><div class="shimmer shimmer-thumbnail border-rounded"></div></td>
                                                    </tr>
                                                    <tr>
                                                        <td><div class="shimmer shimmer-thumbnail border-rounded"></div></td>
                                                        <td><div class="shimmer shimmer-thumbnail border-rounded"></div></td>
                                                        <td><div class="shimmer shimmer-thumbnail border-rounded"></div></td>
                                                        <td><div class="shimmer shimmer-thumbnail border-rounded"></div></td>
                                                    </tr>
                                                    <tr>
                                                        <td><div class="shimmer shimmer-thumbnail border-rounded"></div></td>
                                                        <td><div class="shimmer shimmer-thumbnail border-rounded"></div></td>
                                                        <td><div class="shimmer shimmer-thumbnail border-rounded"></div></td>
                                                        <td><div class="shimmer shimmer-thumbnail border-rounded"></div></td>
                                                    </tr>

                                                </>
                                            )
                                        }
                                    </tbody>
                                </Table>
                                {loading &&
                                    <div className='loader-area'>
                                        <div class="lds-roller">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                    </div>
                                }
                            </div>

                            <div className="paginator">
                                <span className="paginator-pages">{address === '' ? (page * 20) : 1} from {count ? count : '-'}</span>

                                <ul className="paginator-list">
                                    {address === '' &&
                                        <li>
                                            <a href="#sec" onClick={() => {
                                                setLoading(true);
                                                setPage(1);
                                            }}>
                                                First
                                            </a>
                                        </li>
                                    }
                                    {page > 1 &&
                                        <li>
                                            <a href="#sec" onClick={() => {
                                                setLoading(true);
                                                setPage((prev) => prev - 1 >= 1 ? prev - 1 : 1);
                                            }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17,11H9.41l3.3-3.29a1,1,0,1,0-1.42-1.42l-5,5a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l5,5a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L9.41,13H17a1,1,0,0,0,0-2Z"></path>
                                                </svg>
                                            </a>
                                        </li>
                                    }
                                    <li className="active">
                                        <a href="#sec" onClick={() => {

                                        }}>{page}</a>
                                    </li>
                                    {Math.ceil(count / 20) >= (page + 1) &&
                                        <li>
                                            <a href="#sec" onClick={() => {
                                                setPage(page + 1);
                                                setLoading(true);
                                            }}>{page + 1}</a>
                                        </li>
                                    }
                                    {Math.ceil(count / 20) >= (page + 2) &&
                                        <li>
                                            <a href="#sec" onClick={() => {
                                                setPage(page + 2);
                                                setLoading(true);
                                            }}>{page + 2}</a>
                                        </li>
                                    }
                                    {Math.ceil(count / 20) >= (page + 3) &&
                                        <li>
                                            <a href="#sec" onClick={() => {
                                                setPage(page + 3);
                                                setLoading(true);
                                            }}>{page + 3}</a>
                                        </li>
                                    }
                                    {Math.ceil(count / 20) > page &&
                                        <li>
                                            <a href="#sec" onClick={() => {
                                                setLoading(true);
                                                setPage((prev) => prev + 1 >= 1 ? prev + 1 : 1);
                                            }} >
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M17.92,11.62a1,1,0,0,0-.21-.33l-5-5a1,1,0,0,0-1.42,1.42L14.59,11H7a1,1,0,0,0,0,2h7.59l-3.3,3.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l5-5a1,1,0,0,0,.21-.33A1,1,0,0,0,17.92,11.62Z"></path></svg>
                                            </a>
                                        </li>
                                    }
                                    {address === '' &&
                                        <li>
                                            <a href="#sec" onClick={() => {
                                                setLoading(true);
                                                setPage(Math.ceil(count / 20));
                                            }}>
                                                Last
                                            </a>
                                        </li>
                                    }
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </div>
                <img class="group" src={lineRight} alt="Group" />
                {/* <img class="group-1" src={lineLeft} alt="Group" /> */}
            </Container>
        </div>
    )
}
